import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionComponent } from '@shared/components/accordion/accordion.component';
import { BadgeComponent } from '@shared/components/badge/badge.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { ChecklistInputComponent } from '@shared/components/checklist-input/checklist-input.component';
import {
  ChecklistTableColumnDefDirective,
  ChecklistTableComponent
} from '@shared/components/checklist-table/checklist-table.component';
import { DataTableComponent } from '@shared/components/data-table/data-table.component';
import { DateRangePickerComponent } from '@shared/components/date-range-picker/date-range-picker.component';
import { DatepickerComponent } from '@shared/components/datepicker/datepicker.component';
import { DynamicTextareaComponent } from '@shared/components/dynamic-textarea/dynamic-textarea.component';
import { GuidedTourComponent } from '@shared/components/guided-tour/guided-tour.component';
import { ImageHandlerComponent } from '@shared/components/image-handler/image-handler.component';
import { InformationBannerComponent } from '@shared/components/information-banner/information-banner.component';
import { InformationComponent } from '@shared/components/information/information.component';
import { ListTableComponent } from '@shared/components/list-table/list-table.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { MapComponent } from '@shared/components/map/map.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { NumberInputComponent } from '@shared/components/number-input/number-input.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { RadioGroupComponent } from '@shared/components/radio-group/radio-group.component';
import {
  SearchSelectComponent,
  SearchSelectHeaderDefDirective,
  SearchSelectHeaderLabelDefDirective,
  SearchSelectOptionLabelDefDirective
} from '@shared/components/search-select/search-select.component';
import { SearchComponent } from '@shared/components/search/search.component';
import { StatusComponent } from '@shared/components/status/status.component';
import {
  StepperComponent,
  StepperDefDirective,
  StepperHeaderDefDirective,
  StepperLabelDefDirective
} from '@shared/components/stepper/stepper.component';
import { TabularViewComponent } from '@shared/components/tabular-view/tabular-view.component';
import { TextInputComponent } from '@shared/components/text-input/text-input.component';
import { TimeInputComponent } from '@shared/components/time-input/time-input.component';
import { ToggleComponent } from '@shared/components/toggle/toggle.component';
import { UploadImageComponent } from '@shared/components/upload-image/upload-image.component';
import { ComponentHostDirective } from '@shared/directives/component-host.directive';
import { DataTableColumnDirective } from '@shared/directives/data-table-column.directive';
import { GlobalErrorHandler } from '@shared/errors/error-handler';
import { RequestMapInterceptor } from '@shared/interceptors/request-map.interceptor';
import { ControlErrorPipe } from '@shared/pipes/control-error/control-error.pipe';
import { LocalizedDatePipe } from '@shared/pipes/localized-date/localized-date.pipe';
import { LocalizedDurationPipe } from '@shared/pipes/localized-duration/localized-duration.pipe';
import { QtyAverageTimePipe } from '@shared/pipes/qty-average-time/qty-average-time.pipe';
import { QtyDistancePipe } from '@shared/pipes/qty-distance/qty-distance.pipe';
import { QtyLengthPipe } from '@shared/pipes/qty-length/qty-length.pipe';
import { QtyTimePipe } from '@shared/pipes/qty-time/qty-time.pipe';
import { SearchFilterPipe } from '@shared/pipes/search/search-filter.pipe';
import { SharedComponent } from '@shared/shared.component';
import { ButtonModule } from '@teamsp/components/button';
import { LOADER_COMPONENT_TOKEN, LoaderModule } from '@teamsp/components/loader';
import { EquipmentIdentifierPipe } from '@todo/pipes/equipment/equipment.pipe';

@NgModule({
  declarations: [
    AccordionComponent,
    BadgeComponent,
    CheckboxComponent,
    ChecklistInputComponent,
    ChecklistTableColumnDefDirective,
    ChecklistTableComponent,
    ComponentHostDirective,
    ControlErrorPipe,
    DataTableColumnDirective,
    DataTableComponent,
    DatepickerComponent,
    DateRangePickerComponent,
    DynamicTextareaComponent,
    EquipmentIdentifierPipe,
    GuidedTourComponent,
    ImageHandlerComponent,
    InformationBannerComponent,
    InformationComponent,
    ListTableComponent,
    LoaderComponent,
    LocalizedDatePipe,
    LocalizedDurationPipe,
    MapComponent,
    ModalComponent,
    NumberInputComponent,
    PageHeaderComponent,
    PaginatorComponent,
    QtyAverageTimePipe,
    QtyDistancePipe,
    QtyLengthPipe,
    QtyTimePipe,
    RadioGroupComponent,
    SearchComponent,
    SearchFilterPipe,
    SearchSelectComponent,
    SearchSelectHeaderDefDirective,
    SearchSelectHeaderLabelDefDirective,
    SearchSelectOptionLabelDefDirective,
    SharedComponent,
    StatusComponent,
    StepperComponent,
    StepperDefDirective,
    StepperHeaderDefDirective,
    StepperLabelDefDirective,
    TabularViewComponent,
    TextInputComponent,
    TimeInputComponent,
    ToggleComponent,
    UploadImageComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    NgbModule,
    NgSelectModule,
    OverlayModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    TranslateModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: RequestMapInterceptor, multi: true },
    { provide: LOADER_COMPONENT_TOKEN, useValue: LoaderComponent },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DecimalPipe,
    EquipmentIdentifierPipe,
    LocalizedDatePipe,
    LocalizedDurationPipe,
    QtyLengthPipe,
    QtyTimePipe
  ],
  exports: [
    AccordionComponent,
    BadgeComponent,
    ButtonModule,
    CheckboxComponent,
    ChecklistInputComponent,
    ChecklistTableColumnDefDirective,
    ChecklistTableComponent,
    ComponentHostDirective,
    ControlErrorPipe,
    DataTableColumnDirective,
    DataTableComponent,
    DatepickerComponent,
    DateRangePickerComponent,
    DynamicTextareaComponent,
    EquipmentIdentifierPipe,
    GuidedTourComponent,
    ImageHandlerComponent,
    InformationBannerComponent,
    InformationComponent,
    ListTableComponent,
    LoaderComponent,
    LoaderModule,
    LocalizedDatePipe,
    LocalizedDurationPipe,
    MapComponent,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    ModalComponent,
    NumberInputComponent,
    PageHeaderComponent,
    PaginatorComponent,
    QtyAverageTimePipe,
    QtyDistancePipe,
    QtyLengthPipe,
    QtyTimePipe,
    RadioGroupComponent,
    SearchComponent,
    SearchFilterPipe,
    SearchSelectComponent,
    SearchSelectHeaderDefDirective,
    SearchSelectHeaderLabelDefDirective,
    SearchSelectOptionLabelDefDirective,
    SharedComponent,
    StatusComponent,
    StepperComponent,
    StepperDefDirective,
    StepperHeaderDefDirective,
    StepperLabelDefDirective,
    TabularViewComponent,
    TextInputComponent,
    TimeInputComponent,
    ToggleComponent,
    TranslateModule,
    UploadImageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
