import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[selectOptionLabelDef]'
})
export class SearchSelectOptionLabelDefDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[selectHeaderLabelDef]'
})
export class SearchSelectHeaderLabelDefDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[selectHeaderDef]'
})
export class SearchSelectHeaderDefDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

export interface ItemSelectionChangeEvent {
  value: any;
  operation: 'addItem' | 'removeItem';
}

@Component({
  selector: 'search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SearchSelectComponent }]
})
export class SearchSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() createIfNotFound = false;
  @Input() placeholder = '';
  @Input() items: any[] = [];
  @Input() label = '';
  @Input() size = 'sm'; // sm | md
  @Input() readonly = false;
  @Input() id: string;
  @Input() showGreyInput = false;
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() searchable = false;
  @Input() headerLabel: string;
  @Input() tooltip: string;
  @Input() clearable = false;
  @Input() showSearchInput = true;
  @Input() isMultiselect = false;
  @Input() value = '';
  @Input() closeOnSelect = true;
  @Input() hideSelected = true;
  @Input() inlineMultipleSelect = false;
  @Input() filterPredicate: (item: any, searchString: string) => boolean = null;
  @Output() itemSelected = new EventEmitter<ItemSelectionChangeEvent>();
  @Output() valueChange = new EventEmitter<any | Array<any>>();
  @ContentChild(SearchSelectOptionLabelDefDirective) labelDef: SearchSelectOptionLabelDefDirective;
  @ContentChild(SearchSelectHeaderLabelDefDirective) headerLabelDef: SearchSelectHeaderLabelDefDirective;
  @ContentChild(SearchSelectHeaderDefDirective) headerDef: SearchSelectHeaderDefDirective;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('select', { static: true }) selectContainer: NgSelectComponent;

  selectControl: FormControl<any | Array<any>> = new FormControl();
  searchTermControl: FormControl = new FormControl('');

  /** if there is a filterPredicate passed to this component
   * then search-select will take care of filtering
   * and only pass the filtered items to <ng-select> component */
  filteredItems: any = [];

  onChange: (value: any) => void;
  onTouched: () => void;
  private sub: Subscription;

  writeValue(value: any): void {
    this.selectControl.patchValue(value, { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.selectControl.enabled) {
      this.selectControl.disable({ emitEvent: false });
    } else if (this.selectControl.disabled) {
      this.selectControl.enable({ emitEvent: false });
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  ngOnInit(): void {
    this.sub = this.selectControl.valueChanges.subscribe((value) => this.emitValue(value));
    this.sub.add(this.searchTermControl.valueChanges.subscribe((searchTerm: string) => this.onFilter(searchTerm)));
  }

  private emitValue(value: any | Array<any>): void {
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
    this.valueChange.emit(value);
  }

  onContainerClick() {
    this.markAsTouched();
    if (this.showSearchInput && this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }

  selectSearchTerm() {
    const searchTerm = this.searchTermControl.value;
    this.selectControl.patchValue(this.label ? { [this.label]: searchTerm } : searchTerm);
    this.selectContainer.close();
  }

  isPrimitiveValue(item: any) {
    return typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean';
  }

  onFilter(searchString: string) {
    // include all items if no search string is specified
    if (!searchString) {
      this.filteredItems = [...this.items];
    }
    // use the custom filter predicate if there is one
    if (!!this.filterPredicate) {
      this.filteredItems = this.items.filter((item) => this.filterPredicate(item, searchString));
      return;
    }
    // filter the items based on their primitive value if no filter predicate is specified
    this.filteredItems = this.items.filter((item) => {
      const filterableItem = this.isPrimitiveValue(item) ? String(item) : String(item[this.label]);
      return filterableItem.toLowerCase()?.includes(searchString?.toLowerCase());
    });
  }

  addItem(value: any) {
    this.itemSelected.emit({ value, operation: 'addItem' });
  }

  removeItem(value: any) {
    this.itemSelected.emit({ value, operation: 'removeItem' });
  }

  onContainerClose() {
    if (this.showSearchInput) {
      this.searchTermControl.patchValue('');
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
