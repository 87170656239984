import { SortDirection } from '@angular/material/sort';

export enum DataTableHeaderFilterType {
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
  SEARCH = 'search'
}

export interface IDataTableHeader {
  fieldName: string;
  title?: string;
  /** by default every column is filterable */
  filterable?: boolean;
  /** by default the filterType is 'search' */
  filterType?: DataTableHeaderFilterType;
  /** needs to be specified if the `filerType` property is `select` */
  filterOptions?: string[];
  /** by default every column is sortable */
  sortable?: boolean;
  /** the items will be sorted by the first column that has this property specified */
  defaultSort?: SortDirection;
  /** name of the custom template for this specific column.
   * template needs to be added inside the app-data-table component with the corresponding template-name param. */
  customTemplate?: string;
  /** if this value accessor is specified, row items will be filtered
   * based on the value returned by this value accessor (if applicable)
   * and if there's no customTemplate specified, the value from the valueAccessor will be displayed */
  valueAccessor?: (row: any) => string;
  /** if the sorting value accessor is specified, the returned value will be used for sorting */
  sortingValueAccessor?: (row: any) => any;
}

export const DATA_TABLE_STORAGE_KEY = 'SP_DATA_TABLE_CONFIG';
export const DATA_TABLE_DEFAULT_CONTEXT = 'sp_data_table_default_context';

export interface DataTableContextInstanceConfig {
  showFilterRow: boolean;
}

export interface DataTableContextConfig {
  [key: string]: DataTableContextInstanceConfig;
}

export const DATA_TABLE_DEFAULT_CONTEXT_CONFIG: DataTableContextInstanceConfig = { showFilterRow: true };
