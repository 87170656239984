import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '@shared/components/modal/modal.component';
import {
  ModalConfig,
  ModalContentComponent,
  ModalEventType,
  ModalInstance,
  DEFAULT_MODAL_CONFIG
} from '@shared/models/modal';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private ngbModal: NgbModal) {
  }

  open<T extends ModalContentComponent<D>, D = ReturnType<T['valueAccessor']>>(config: ModalConfig<T>): ModalInstance<T, D> {
    const modalConfig: ModalConfig<T> = { ...(DEFAULT_MODAL_CONFIG as ModalConfig<T>), ...config };
    let modalCompInstance: ModalComponent<T, D> = null;

    const ngbModalOptions: NgbModalOptions = {
      animation: false,
      backdrop: modalConfig.dismissible,
      size: modalConfig.size,
      keyboard: modalConfig.dismissible,
      windowClass: modalConfig.popoverStyle,
      centered: true,
      injector: modalConfig?.injector,
      beforeDismiss: () => {
        if (modalCompInstance.disabledEvents[ModalEventType.DISMISS]) {
          return false;
        }
        modalCompInstance.modalClose.emit({ eventType: ModalEventType.DISMISS });
        return true;
      }
    };

    const modalRef = this.ngbModal.open(ModalComponent, ngbModalOptions);
    modalCompInstance = modalRef.componentInstance as ModalComponent<T, D>;
    modalCompInstance.config = config;

    // prepare open modal instance
    const modal: ModalInstance<T, D> = {
      close: modalCompInstance.modalClose.pipe(take(1))
    };

    if (modalConfig.contentComponent) {
      modal.contentComponentInit = modalCompInstance.contentComponentInit.pipe(take(1));
      // when the modal content has init, save the reference in the modal instance
      modalCompInstance.contentComponentInit
        .pipe(take(1))
        .subscribe((comp: T) => (modal.contentComponentInstance = comp));
    }

    return modal;
  }

  close() {
    if (this.ngbModal.hasOpenModals()) {
      this.ngbModal.dismissAll();
    }
  }
}
