import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { Feature } from '@core/models/user-profile.model';
import { UserStoreService } from '@core/services/user-store/user-store.service';

export interface NavbarMenuItem {
  icon: string;
  label: string;
  /** if routerLink is specified, it will redirect inside the application */
  routerLink?: string;
  /** if absoluteLink is specified, it will redirect to an external URL, triggering a page reload */
  absoluteLink?: string;
  /** default value is `true` */
  isVisible?: boolean;
  /** indicate that this item is a new addition and should be highlighted */
  isHighlighted?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) private env: Environment,
    private userService: UserStoreService
  ) {}

  getChecksItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-check',
    label: 'Kontroller', // Checks
    routerLink: '/check',
    isVisible: this.canAccessCheckBeforeDrive()
  });

  getChecklistsItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-checklist',
    label: 'Checklistor', // Checklists
    routerLink: '/checklist',
    isVisible: this.canAccessCheckBeforeDrive()
  });

  getTodoItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-todo',
    label: 'AttGöra', // To do
    routerLink: '/to-do'
  });

  getCalendarItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-calender',
    label: 'Kalender', // Calendar
    ...(this.canAccessCalendarPage()
      ? { routerLink: '/calendar', isHighlighted: true }
      : { absoluteLink: `${this.env.myScaniaFmpURL}/#/sp/calendar` })
  });

  getDefectReportsItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-defect-reports',
    label: 'Felrapporter', // Defect reports
    routerLink: '/defect-reports'
  });

  getOverviewItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-overview',
    label: 'Översikt', // Overview
    routerLink: '/overview',
    isVisible: this.canAccessOverviewPage(),
    isHighlighted: true
  });

  getServiceCoverageItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-service-coverage',
    label: 'Servicetäckning', // Service coverage
    routerLink: '/service-coverage'
  });

  getWorkshopHistoryItem = (): NavbarMenuItem => ({
    icon: 'cui-scania-fms-workshop-history',
    label: 'Verkstadshistorik', // Workshop history
    routerLink: '/workshop-history'
  });

  getSettingsItem = (): NavbarMenuItem => ({
    icon: 'cui-myscania-app-settings',
    label: 'Inställningar', // Settings
    routerLink: '/settings'
  });

  getMenuItems(): NavbarMenuItem[] {
    const checkBeforeDriveItems = [this.getChecksItem(), this.getChecklistsItem()];
    const overviewItem = this.getOverviewItem();
    const calendarItem = this.getCalendarItem();
    const todoItem = this.getTodoItem();
    const otherItems = [
      this.getDefectReportsItem(),
      this.getServiceCoverageItem(),
      this.getWorkshopHistoryItem(),
      this.getSettingsItem()
    ];

    let items: NavbarMenuItem[] = [];
    if (this.canAccessOverviewPage()) {
      items = [overviewItem, todoItem, calendarItem, ...checkBeforeDriveItems, ...otherItems];
    } else {
      items = [...checkBeforeDriveItems, todoItem, calendarItem, overviewItem, ...otherItems];
    }

    return items.map((item) =>
      // each item is visible by default. it the isVisible property is undefined, set it to true
      Object.assign(item, { isVisible: item.isVisible === undefined ? true : item.isVisible })
    );
  }

  canAccessCheckBeforeDrive(): boolean {
    return this.userService.isFeatureEnabled(Feature.CHECK_BEFORE_DRIVE);
  }

  canAccessOverviewPage(): boolean {
    return this.userService.isFeatureEnabled(Feature.SP_OVERVIEW_PAGE_ACCESS);
  }

  canAccessCalendarPage(): boolean {
    return this.userService.isFeatureEnabled(Feature.SP_CALENDAR_PAGE_ACCESS);
  }
}
