// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginator-container {
  --mat-paginator-container-text-font: var(--sdds-font-family-sans-serif);
  --mat-paginator-container-text-color: var(--sdds-grey-600);
  --mat-paginator-container-background-color: transparent;
  --mat-paginator-container-text-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: var(--mat-paginator-container-text-size);
  color: var(--mat-paginator-container-text-color);
}
.paginator-container .page-count {
  flex: 1;
  padding-right: 2rem;
}
.paginator-container.sdds-input {
  --mat-paginator-container-text-color: var(--sdds-black);
  justify-content: flex-start;
  margin-top: -25px;
  margin-left: 1rem;
}
.paginator-container.sdds-input .page-count {
  flex: none;
}
.paginator-container ::ng-deep .mat-mdc-paginator-container {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/paginator/paginator.component.scss"],"names":[],"mappings":"AAAA;EACE,uEAAA;EACA,0DAAA;EACA,uDAAA;EACA,2CAAA;EAEA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mDAAA;EACA,gDAAA;AAAF;AAEE;EACE,OAAA;EACA,mBAAA;AAAJ;AAGE;EACE,uDAAA;EAEA,2BAAA;EACA,iBAAA;EACA,iBAAA;AAFJ;AAII;EACE,UAAA;AAFN;AAME;EACE,UAAA;AAJJ","sourcesContent":[".paginator-container {\n  --mat-paginator-container-text-font: var(--sdds-font-family-sans-serif);\n  --mat-paginator-container-text-color: var(--sdds-grey-600);\n  --mat-paginator-container-background-color: transparent;\n  --mat-paginator-container-text-size: 1.2rem;\n\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  font-size: var(--mat-paginator-container-text-size);\n  color: var(--mat-paginator-container-text-color);\n\n  .page-count {\n    flex: 1;\n    padding-right: 2rem;\n  }\n\n  &.sdds-input {\n    --mat-paginator-container-text-color: var(--sdds-black);\n\n    justify-content: flex-start;\n    margin-top: -25px;\n    margin-left: 1rem;\n\n    .page-count {\n      flex: none;\n    }\n  }\n\n  ::ng-deep .mat-mdc-paginator-container {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
