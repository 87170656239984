import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentHostDirective } from '@shared/directives/component-host.directive';
import { ModalConfig, ModalContentComponent, ModalEventType, ModalResolution } from '@shared/models/modal';
import { ButtonType } from '@teamsp/components/button';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent<T extends ModalContentComponent<D>, D> implements OnInit {
  @ViewChild(ComponentHostDirective, { static: true }) componentHost: ComponentHostDirective;
  @Input() config: ModalConfig<T> = {};
  @Output() modalClose: EventEmitter<ModalResolution<D>> = new EventEmitter();
  @Output() contentComponentInit: EventEmitter<T> = new EventEmitter();

  disabledEvents: { [key in ModalEventType]: boolean } = {
    [ModalEventType.NEGATIVE]: false,
    [ModalEventType.POSITIVE]: false,
    [ModalEventType.NEUTRAL]: false,
    [ModalEventType.DISMISS]: false
  };

  activeComponent: T;

  ButtonType = ButtonType;

  // template usage
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public ModalEventType = ModalEventType;

  constructor(public activeModal: NgbActiveModal) {}

  async action(eventType: ModalEventType, data?: D) {
    // if no data is directly emitted via `modalAction` method
    // use the `valueAccessor` for the modal content component if it exists to emit data to parent
    const resolutionData = data || (this.activeComponent && this.activeComponent.valueAccessor());

    const goThrough = () => {
      this.modalClose.emit({ eventType, data: resolutionData });
      this.activeModal.close();
    };
    if (!this.config.contentComponent || !this.activeComponent) {
      return goThrough();
    }
    // if a content component is specified, we need to check if there is a hook
    // that can prevent the modal from closing
    // use Promise.resolve to normalize the truth value (either boolean or Promise<boolean>)
    let shouldGoThrough = true;
    if (eventType === ModalEventType.POSITIVE) {
      shouldGoThrough = await Promise.resolve(this.activeComponent.onPositiveAction());
    }
    if (eventType === ModalEventType.NEGATIVE) {
      shouldGoThrough = await Promise.resolve(this.activeComponent.onNegativeAction());
    }
    if (eventType === ModalEventType.NEUTRAL) {
      shouldGoThrough = await Promise.resolve(this.activeComponent.onNeutralAction());
    }
    if (eventType === ModalEventType.DISMISS) {
      shouldGoThrough = await Promise.resolve(this.activeComponent.onDismissAction());
    }

    if (shouldGoThrough) {
      goThrough();
    }
  }

  setModalEventDisabled(eventType: ModalEventType, disabled: boolean) {
    this.disabledEvents[eventType] = disabled;
  }

  addModalAction(eventType: ModalEventType, label: string, icon?: string) {
    this.config.buttons = { ...this.config.buttons, [eventType]: { text: label, icon } };
  }

  removeModalAction(eventType: ModalEventType) {
    if (this.config.buttons && this.config.buttons[eventType]) {
      delete this.config.buttons[eventType];
    }
  }

  ngOnInit() {
    // if the modal content is a string, no action is needed
    if (!this.config.contentComponent) {
      return;
    }

    const viewContainer = this.componentHost.viewContainerRef;
    viewContainer.clear();
    const compRef = viewContainer.createComponent(this.config.contentComponent);

    // link the setModalEventDisabled method to be able to set a modal event disabled state
    // from within the modal content component
    compRef.instance.setModalEventDisabled = (event, disabled) => this.setModalEventDisabled(event, disabled);

    compRef.instance.addModalAction = (event, label, icon) => this.addModalAction(event, label, icon);

    compRef.instance.removeModalAction = (event) => this.removeModalAction(event);

    // link the close method to be able to close the modal
    // from within the modal content component
    compRef.instance.modalAction = (event, data) => this.action(event, data);
    // set the modal content component inputs
    if (this.config.contentComponentInputs) {
      Object.assign(compRef.instance, this.config.contentComponentInputs);
    }

    this.activeComponent = compRef.instance;
    this.contentComponentInit.emit(compRef.instance);
  }
}
