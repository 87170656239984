import { CommonModule } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@core/core.module';
import { environment } from '@environments/environment';
import { TranslationUtilsModule } from '@myscania/translation-utils-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NoInternetToastrComponent } from '@shared/components/no-internet-toastr/no-internet-toastr.component';
import { NoNetworkComponent } from '@shared/components/no-network/no-network.component';
import { SharedModule } from '@shared/shared.module';
import { KeycloakAngularModule } from 'keycloak-angular';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent, NoInternetToastrComponent, NoNetworkComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    CoreModule.forRoot(environment),
    EffectsModule.forRoot([]),
    KeycloakAngularModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    StoreModule.forRoot({}, {}),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    TranslationUtilsModule
  ],
  providers: [provideHttpClient()],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
