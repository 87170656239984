import { Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

export class ModalContentComponent<D = any> {
  /** Prevent a modal event from happening
   *
   * @example
   * this.setModalEventDisabled(ModalEventType.POSITIVE, true);
   * // ...
   * // some async operation that needs to complete before the modal can be closed
   * // ...
   * this.setModalEventDisabled(ModalEventType.POSITIVE, false);
   */
  setModalEventDisabled: (eventType: ModalEventType, disabled: boolean) => void;

  /** trigger a modal event from inside the modal content component
   *
   * @example
   * submitButtonPress() {
   *   this.modalAction(ModalEventType.POSITIVE);
   * }
   */
  modalAction: (eventType: ModalEventType, data?: D) => void;

  /** Should return the value that needs to be emitted back to the modal's parent component.
   *
   * Instead of the modal parent component manually accessing content component instance properties
   * ```
   * const modalInstance = this.modalService.open(...);
   * modalInstance.close.subscribe(({eventType}) => {
   *    doSomethingWithTheResult(modalInstance.componentInstance.formGroup.value)
   * })
   *```
   * the `valueAccessor` method can be implemented
   * ```
   * valueAccessor() {
   *   return this.formGroup.value;
   * }
   * ```
   * and the parent component can access the data from the modal resolution object
   * ```
   * modalInstance.close.subscribe(({eventType, data}) => {
   *   doSomethingWithTheResult(data);
   * })
   * ```
   */
  valueAccessor: () => D = () => undefined;

  addModalAction: (eventType: ModalEventType, label: string, icon?: string) => void;

  removeModalAction: (eventType: ModalEventType) => void;

  /** if implementations for these hooks exist
   * they will be called right before closing and emitting their corresponding closing event.
   * If they return false or promise that resolves to false
   * the modal will not be closed and the event will not be emitted */
  onPositiveAction(): boolean | Promise<boolean> {
    return true;
  }
  onNegativeAction(): boolean | Promise<boolean> {
    return true;
  }
  onNeutralAction(): boolean | Promise<boolean> {
    return true;
  }
  onDismissAction(): boolean | Promise<boolean> {
    return true;
  }
}

export enum ModalEventType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  DISMISS = 'dismiss'
}

export interface ModalResolution<D> {
  eventType: ModalEventType;
  data?: D;
}

export interface ModalButton {
  text: string;
  containerClass?: string;
  icon?: string;
}

export interface ModalConfig<C extends ModalContentComponent = any> {
  title?: string;
  contentText?: string;
  contentComponent?: Type<C>;
  contentComponentInputs?: Partial<C>;
  dismissible?: boolean;
  injector?: Injector;
  popoverStyle?: 'modal' | 'drawer-fill' | 'drawer';
  size?: 'md' | 'sm' | 'lg' | 'xl';
  buttons?: {
    positive?: ModalButton;
    negative?: ModalButton;
    neutral?: ModalButton;
  };
}

export const DEFAULT_MODAL_CONFIG: ModalConfig = {
  dismissible: true,
  size: 'md',
  popoverStyle: 'modal'
};

export interface ModalInstance<C extends ModalContentComponent<D>, D> {
  close: Observable<ModalResolution<D>>;
  contentComponentInstance?: C;
  contentComponentInit?: Observable<C>;
}
