import { inject, Inject, Injectable, InjectionToken } from '@angular/core';
import { loadAdobeAnalytics } from '@core/init/adobe-analytics-loader';
import { Environment, ENVIRONMENT_TOKEN } from '@core/models/environment.model';
import { CookieService } from '@core/services/cookie/cookie.service';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { Guid } from 'guid-typescript';
import { filter, firstValueFrom } from 'rxjs';

export type AnalyticsViewContext = string;

export const ANALYTICS_VIEW_CONTEXT = new InjectionToken<AnalyticsViewContext>('ANALYTICS_VIEW_CONTEXT', {
  providedIn: 'root',
  factory: () => 'sp:default-view'
});

export enum GeneralAnalyticsEvent {
  PAGE_VIEW = 'page_view',
  LIST_TABLE_ITEM_SELECT = 'list_table_item_select',
  DATA_TABLE_ITEM_SELECT = 'data_table_item_select',
  EXPORT_EXCEL = 'export_excel',
  CONSOLE_ERROR = 'console_error'
}

export type AnalyticsEventData = Record<string, any>;

export interface DecoratedAnalyticsEventData extends AnalyticsEventData {
  session: {
    sessionReference: string;
    customerReference: string;
    language: string;
    customerName: string;
    timezone: string;
    country: string;
    screenSize: {
      width: number;
      height: number;
    };
    userAgent: string;
  };
}

export interface AnalyticsPageEventData extends AnalyticsEventData {
  viewContext: string;
  route: string;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private sessionReference: Guid;
  private hasCookieConsent = false;
  private env: Environment;
  private cookieService: CookieService;
  private userService: UserStoreService;

  constructor() {
    this.cookieService = inject(CookieService);
    this.userService = inject(UserStoreService);
    this.sessionReference = Guid.create();
    this.env = inject(ENVIRONMENT_TOKEN);
  }

  init() {
    this.hasCookieConsent = this.cookieService.getConsentCookiePreferences()?.performance;
    if (!this.hasCookieConsent) {
      return;
    }
    // reuse adobe analytics loader that was previously used as an app initializer
    loadAdobeAnalytics(this.env)();
  }

  async pushEvent(eventName: string, eventData: AnalyticsEventData = {}) {
    if (!this.hasCookieConsent) {
      return;
    }

    const userProfile = await firstValueFrom(this.userService.profile$.pipe(filter((profile) => !!profile)));
    const decoratedEvent: DecoratedAnalyticsEventData = {
      ...eventData,
      session: {
        // append session data to the event
        country: userProfile.customer.country,
        customerName: userProfile.customer.name,
        customerReference: userProfile.customer.externalCustomerReference,
        language: userProfile.language,
        screenSize: { width: window.innerWidth, height: window.innerHeight },
        sessionReference: this.sessionReference.toString(),
        timezone: userProfile.customer.timeZone,
        userAgent: window.navigator.userAgent
      }
    };

    window.adobeDataLayer.push({
      event: eventName,
      // analytics team asked to change the `eventInfo` property to `pageInfo` for `page_view` events
      ...(eventName === GeneralAnalyticsEvent.PAGE_VIEW ? { pageInfo: decoratedEvent } : { eventInfo: decoratedEvent })
    });
  }

  pushPageViewEvent(viewContext: string) {
    return this.pushEvent(GeneralAnalyticsEvent.PAGE_VIEW, {
      viewContext,
      route: window.location.href
    });
  }

  pushConsoleErrorEvent(error: Error) {
    return this.pushEvent(GeneralAnalyticsEvent.CONSOLE_ERROR, {
      errorMessage: String(error),
      route: window.location.href
    });
  }
}
