/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthSsoGuard } from '@core/guards/auth-sso/auth-sso.guard';
import { RolesGuard } from '@core/guards/roles/roles.guard';
import { ServicePlanningRouteRedirectGuard } from '@core/guards/service-planning-route-redirect/service-planning-route-redirect.guard';
import { Feature } from '@core/models/user-profile.model';
import { NoNetworkComponent } from '@shared/components/no-network/no-network.component';
import { AppComponent } from 'src/app/app.component';

const routes: Routes = [
  {
    path: 'check',
    loadChildren: () => import('./check/check.module').then((m) => m.CheckModule),
    canActivate: [AuthSsoGuard, RolesGuard],
    data: { requiredFeature: Feature.CHECK_BEFORE_DRIVE }
  },
  {
    path: 'checklist',
    canActivate: [AuthSsoGuard, RolesGuard],
    loadChildren: () => import('./checklist/checklist.module').then((m) => m.CheckListModule),
    data: { requiredFeature: Feature.CHECK_BEFORE_DRIVE }
  },
  {
    path: 'to-do',
    canActivate: [AuthSsoGuard],
    loadChildren: () => import('./to-do/to-do.module').then((m) => m.ToDoModule)
  },
  {
    path: 'calendar',
    canActivate: [AuthSsoGuard, RolesGuard],
    loadChildren: () => import('./calendar/calendar-view.module').then((m) => m.CalendarViewModule),
    data: { requiredFeature: Feature.SP_CALENDAR_PAGE_ACCESS }
  },
  {
    path: 'defect-reports',
    canActivate: [AuthSsoGuard],
    loadChildren: () => import('./defect-reports/defect-reports.module').then((m) => m.DefectReportsModule)
  },
  {
    path: 'overview',
    canActivate: [AuthSsoGuard, RolesGuard],
    loadChildren: () => import('./overview/overview.module').then((m) => m.OverviewModule),
    data: { requiredFeature: Feature.SP_OVERVIEW_PAGE_ACCESS }
  },
  {
    path: 'service-coverage',
    canActivate: [AuthSsoGuard],
    loadChildren: () => import('./service-coverage/service-coverage.module').then((m) => m.ServiceCoverageModule)
  },
  {
    path: 'workshop-history',
    canActivate: [AuthSsoGuard],
    loadChildren: () => import('./workshop-history/workshop-history.module').then((m) => m.WorkshopHistoryModule)
  },
  {
    path: 'settings',
    canActivate: [AuthSsoGuard],
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: 'mac-plan/:chassisNumber',
    canActivate: [AuthSsoGuard],
    loadChildren: () => import('./mac-plan/mac-plan.module').then((m) => m.MacPlanModule)
  },
  {
    path: 'no-network',
    component: NoNetworkComponent
  },
  {
    // match all routes in the form of `/service planning/{rest-of-route}`
    // then use the `ServicePlanningRouteRedirectGuard` to redirect to `{rest-of-route}`
    // used to keep backwards compatibility with fmp-frontend, until changes are deployed there as well
    path: 'service-planning',
    children: [{ path: '**', component: AppComponent }],
    canActivate: [ServicePlanningRouteRedirectGuard],
    component: AppComponent
  },
  {
    path: '**',
    redirectTo: 'to-do'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
