/* eslint-disable lodash/prefer-includes */
import {
  Component,
  ContentChildren,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import {
  ANALYTICS_VIEW_CONTEXT,
  AnalyticsService,
  AnalyticsViewContext,
  GeneralAnalyticsEvent
} from '@core/services/analytics/analytics.service';
import { PaginatorIntl } from '@shared/components/paginator/paginator-intl.service';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { DataTableColumnDirective } from '@shared/directives/data-table-column.directive';
import {
  DATA_TABLE_DEFAULT_CONTEXT,
  DATA_TABLE_DEFAULT_CONTEXT_CONFIG,
  DATA_TABLE_STORAGE_KEY,
  DataTableContextConfig,
  DataTableHeaderFilterType,
  IDataTableHeader
} from '@shared/models/data-table.model';
import { ModalService } from '@shared/services/modal/modal.service';
import { Subscription, filter } from 'rxjs';
import { DataTableService } from './data-table.service';

export interface ViewContext<T> {
  $implicit: T;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss', '../search-select/search-select.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }]
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy {
  PAGINATOR_CONTEXT = 'sp_paginator_data_table_context';
  ACTION_FILTER_COLUMN = 'action_filter';

  FilterType = DataTableHeaderFilterType;

  @Input() headers: IDataTableHeader[] = [];
  @Input() data = [];
  @Input() caption: string;
  @Input() isLoading: boolean;
  @Input() isSelectable = true;
  @Input() tableContext: string = DATA_TABLE_DEFAULT_CONTEXT;
  @Input() keyAccessor: (row: any) => string = () => '';
  /** Below variable is the deafult data in case 'selectedItemIndex' is not avaiable
   * for a fall back tp open the modal with default data*/
  /**  In current scenario  this is of type Defect */
  @Input() defaultSelectionDetails = null;

  @Output() tableRowClicked: EventEmitter<any> = new EventEmitter();
  @Output() columnSorted: EventEmitter<Sort> = new EventEmitter();
  @Output() page: EventEmitter<any[]> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
  @ContentChildren(DataTableColumnDirective) templateRefs: QueryList<DataTableColumnDirective>;

  columnsHeaderMap = new Map<string, IDataTableHeader>();
  displayedColumns: string[];
  displayedColumnFilters: string[];
  hasFilterableFields: boolean;
  defaultSortColumn: string = null;
  defaultSortDirection: SortDirection = null;
  dataSource: MatTableDataSource<any>;
  expandFilterRow: boolean;
  filteredValues: any;
  filterValuesMap: Record<string, string | string[]>;
  selectedRowKey = '';
  previousSelectedRef = '';

  private sub: Subscription = new Subscription();
  constructor(
    @Inject(ANALYTICS_VIEW_CONTEXT) private analyticsViewContext: AnalyticsViewContext,
    private analyticsService: AnalyticsService,
    private dataTableService: DataTableService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadContextConfig();
    this.cacheTableHeaders(this.headers);
    this.dataSource = this.getDataSource(this.data);

    this.selectedRowKey = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.params['ref'] : '';

    const itemToBeSelectedIndex = this.getItemIndex(this.dataSource.data, this.selectedRowKey);
    if (itemToBeSelectedIndex > -1) {
      this.selectRow(this.dataSource.data[itemToBeSelectedIndex]);
    }

    this.sub.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          if (event.navigationTrigger === 'popstate') {
            this.modalService.close();
          }
        })
    );
    this.sub.add(
      this.dataTableService.previousRef.subscribe((ref) => {
        this.previousSelectedRef = ref;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue) {
      this.dataSource = this.getDataSource(changes['data'].currentValue);
      /** Below code added, to persist the filter after updating  the data*/
      for (const key in this.filterValuesMap) {
        if (this.filterValuesMap[key] !== '') {
          this.applyFilter(this.filterValuesMap[key], key);
        }
      }

      this.configureDataSource();
    }
    if (changes['headers']?.currentValue) {
      this.cacheTableHeaders(changes['headers'].currentValue);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getItemIndex(dataSource: any[], itemKey: any): number {
    return dataSource.findIndex((other) => this.keyAccessor(other) === itemKey);
  }

  selectRow(row: any) {
    if (!this.dataSource.data.length) {
      return;
    }
    const data = this.dataSource._orderData(this.dataSource.filteredData);

    const rowToBeSelected = row;
    if (!rowToBeSelected) {
      return;
    }

    const itemKey = this.keyAccessor(rowToBeSelected);
    const itemIndex = this.getItemIndex(data, itemKey) + 1;

    // go to the page of the selected item
    // paginator needs to be initialized before accessing the pageSize property
    // this observable emits only once, no need to unsubscribe
    this.paginator.controller.initialized.subscribe(() => {
      // paginator page index is zero-based
      const page = Math.ceil(itemIndex / this.paginator.controller.pageSize) - 1;
      this.paginator.goToPage(page);
    });

    if (itemKey) {
      this.router.navigate([itemKey], { relativeTo: this.route });
    }

    // Don't want to open modal if previousSelectedRef  has value, in case of update and create
    // this.previousSelectedRef will only be available after update and create else will be ''
    // on update and create storing the selected ref in service, so value will be same and modal will not open
    if (this.previousSelectedRef !== itemKey) {
      // This clearing the data, catering the use case of clicking the same row again
      this.dataTableService.updateReference(itemKey);
      this.tableRowClicked.emit(rowToBeSelected);

      this.analyticsService.pushEvent(GeneralAnalyticsEvent.DATA_TABLE_ITEM_SELECT, {
        viewContext: this.analyticsViewContext,
        selectedItemReference: itemKey
      });
    }

    this.selectedRowKey = itemKey;
  }

  getDataSource(data: any[] = []): MatTableDataSource<any> {
    const dataSource = new MatTableDataSource(data);
    dataSource.paginator = this.paginator.controller;
    dataSource.sortingDataAccessor = (item, field) => {
      const header = this.columnsHeaderMap.get(field);
      if (header.sortingValueAccessor) {
        return header.sortingValueAccessor(item);
      }
      if (header.valueAccessor) {
        return header.valueAccessor(item);
      }
      return item[field];
    };

    dataSource.filterPredicate = (item, stringifiedFilterValuesMap) => {
      this.filterValuesMap = JSON.parse(stringifiedFilterValuesMap);
      return this.headers.every((header) => {
        // if `filterable` is specifically se to false we need to ignore this column
        // by considering it a match with all
        if (header.filterable === false) {
          return true;
        }

        let value = String(item[header.fieldName]) || '';
        if (header.valueAccessor) {
          value = String(header.valueAccessor(item)) || '';
        }

        // if option is multi-select or select, we check for exact match
        if (header.filterType === DataTableHeaderFilterType.MULTI_SELECT) {
          const filterStrings: string[] = this.filterValuesMap[header.fieldName] as string[];
          // if there are no options selected, or at least one of the options matches the value, return true
          return filterStrings.length ? filterStrings.some((filterString) => value === filterString) : true;
        }

        if (header.filterType === 'select') {
          const filterString = this.filterValuesMap[header.fieldName];
          return filterString ? filterString === value : true;
        }

        // otherwise, if filter type is plain string, we check for relative match
        const processedFilterString = (this.filterValuesMap[header.fieldName] as string).trim().toLowerCase();
        return value.trim().toLowerCase().includes(processedFilterString);
      });
    };
    dataSource.data = dataSource._orderData(data);
    return dataSource;
  }

  isHeaderFilterable(header: IDataTableHeader) {
    // unless the `filterable` property is specifically set to `false`, the column is filterable
    return header.filterable !== false;
  }

  isHeaderSortable(header: IDataTableHeader) {
    // unless the `sortable` property is specifically set to `false`, the column is sortable
    return header.sortable !== false;
  }

  getHeaderFilterType(header: IDataTableHeader): DataTableHeaderFilterType {
    // `select` filter type is the default value
    return header.filterType || DataTableHeaderFilterType.SEARCH;
  }

  getOptions(header: IDataTableHeader) {
    return this.data ? header.filterOptions || [...new Set(this.data.map((item) => item[header.fieldName]))] : [];
  }

  configureDataSource() {
    this.dataSource.sort = this.sort;

    /** This use case is needed after creating the data , as don't have create ref from widget in this view
     * and  to jump to the created row and paginator in table view, after creating.
     * Updating the DataTableService in create function of the parent component
     */

    const selectedItemKey = this.previousSelectedRef ? this.previousSelectedRef : this.selectedRowKey;
    const selectedItemIndex = selectedItemKey ? this.getItemIndex(this.dataSource.data, selectedItemKey) : -1;
    /** Note: Use case of updating defect which is not present in datasource beacuse of date filter
     * In this case need to pass the default data  from parent to selectRow in order to emit the tableRowClicked
     */
    const selectedData = this.defaultSelectionDetails ? this.defaultSelectionDetails : null;
    this.selectRow(selectedItemIndex > -1 ? this.dataSource.data[selectedItemIndex] : selectedData);
  }

  getTemplate(templateref) {
    return this.templateRefs.find((template: any) => template.columnName === templateref)?.template;
  }

  getUserViewContext(element: any): ViewContext<any> {
    return { $implicit: element };
  }

  cacheTableHeaders(headers: IDataTableHeader[] = []) {
    headers.forEach((header) => this.columnsHeaderMap.set(header.fieldName, header));
    this.displayedColumns = headers.map((item) => item.fieldName);
    this.defaultSortColumn = headers.find((item) => item.defaultSort)?.fieldName;
    this.defaultSortDirection = headers.find((item) => item.defaultSort)?.defaultSort;

    this.hasFilterableFields = headers.some((item) => this.isHeaderFilterable(item));
    if (this.hasFilterableFields) {
      this.displayedColumns.push('action');
      this.displayedColumnFilters = this.headers.map((item) => item.fieldName + '_filter');
      this.displayedColumnFilters.push(this.ACTION_FILTER_COLUMN);
      this.filteredValues = this.headers.reduce((a, v) => ({ ...a, [v.fieldName]: '' }), {});
    }
  }

  loadContextConfig() {
    const localStorageData = localStorage.getItem(DATA_TABLE_STORAGE_KEY);
    const dataTableConfig: DataTableContextConfig = localStorageData ? JSON.parse(localStorageData) : {};
    const instanceConfig = dataTableConfig[this.tableContext] || DATA_TABLE_DEFAULT_CONTEXT_CONFIG;

    this.expandFilterRow = instanceConfig.showFilterRow;
  }

  saveContextFilterRowVisibility(showFilterRow: boolean) {
    const localStorageData = localStorage.getItem(DATA_TABLE_STORAGE_KEY);
    const dataTableConfig: DataTableContextConfig = localStorageData ? JSON.parse(localStorageData) : {};
    const instanceConfig = dataTableConfig[this.tableContext] || DATA_TABLE_DEFAULT_CONTEXT_CONFIG;

    instanceConfig.showFilterRow = showFilterRow;

    localStorage.setItem(
      DATA_TABLE_STORAGE_KEY,
      JSON.stringify({ ...dataTableConfig, [this.tableContext]: instanceConfig })
    );
  }

  toggleFilterRow() {
    this.expandFilterRow = !this.expandFilterRow;
    this.saveContextFilterRowVisibility(this.expandFilterRow);
  }

  rowSelected(row: any) {
    return this.keyAccessor(row) === this.selectedRowKey;
  }

  onClear(fieldName: string) {
    this.applyFilter('', fieldName);
  }

  applyFilter(filterValue: string | string[], fieldName: string) {
    const header = this.columnsHeaderMap.get(fieldName);
    const defaultValue = header.filterType === DataTableHeaderFilterType.MULTI_SELECT ? [] : '';

    this.filteredValues[fieldName] = filterValue || defaultValue;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  getColspan(): number {
    return this.hasFilterableFields ? this.headers?.length + 1 : this.headers?.length;
  }

  sortData(sort: Sort): void {
    this.columnSorted.emit(sort);
  }

  rowClick(row: any) {
    if (!this.isSelectable) {
      return;
    }
    this.previousSelectedRef = '';
    this.selectRow(row);
  }
}
