import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImageHandlerService } from '@shared/components/image-handler/image-handler.service';
import { LoaderService } from '@teamsp/components/loader';
import { ModalEventType } from '@shared/models/modal';
import { ModalService } from '@shared/services/modal/modal.service';
import { QtyService } from '@shared/services/qty/qty.service';
import { ToastrService } from 'ngx-toastr';
import { of, Subscription, zip } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

const DEFAULT_MAX_ITEMS = 5;
const DEFAULT_ITEMS_PER_ROW = 6;

@Component({
  selector: 'image-handler',
  templateUrl: './image-handler.component.html',
  styleUrls: ['./image-handler.component.scss']
})
export class ImageHandlerComponent implements OnInit, OnChanges, OnDestroy {
  LOADER_CONTEXT = 'image_handler_loader_context';

  /** array of image URLs that will be displayed at first in the gallery */
  @Input() images: string[] = [];

  /** maximum number of items in the gallery after which the add button is hidden */
  @Input() maxItems = DEFAULT_MAX_ITEMS;

  /** maximum size in bytes of each item. If set to 0, the limit is disabled */
  @Input() maxItemSize = 0;

  /** maximum number of items per row, so other items will wrap on the next line */
  @Input() itemsPerRow = DEFAULT_ITEMS_PER_ROW;

  /** if set to true, only preview image and thumbnails will be displayed, with no add or remove button */
  @Input() readonly = false;

  /** header of image to display when opened in new tab */
  @Input() imageHeader: string;

  // display selected image if any
  @Input() displaySelectedImage = null;

  /** array of File object representing the added images
   * that need to be processed by the parent */
  @Output() addedImagesChange: EventEmitter<File[]> = new EventEmitter();

  /** array of string objects representing already existing images
   * that were removed from the gallery so the parrent can keep track of it */
  @Output() removedImagesChange: EventEmitter<string[]> = new EventEmitter();

  /** all the displayed images keys from the cache,
   * both for pre-existing images (e.g. from images input), for which the key is the image URL
   * as well as the new unsaved images, for which the key is the file name */
  displayedImageKeys: string[] = [];

  /** array of new added image files, that will be emitted to the parent */
  addedImages: File[] = [];

  /** array of pre-existing images URLs (e.g. from the images array) that were removed by the user
   * this will be emitted to the parent everytime a pre-existing image is removed */
  removedImages: string[] = [];

  /** selected image key from the cache that will be displayed as a preview */
  selectedImageKey: string = null;

  imageCachingSub = new Subscription();

  isImageRemoved = false;

  constructor(
    private imageHandlerService: ImageHandlerService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private qtyService: QtyService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {}

  /** cache base64 data for all image urls in the image service
   * while also setting the loader to true initially and to false after the caching is done */
  private cacheImagesArray(imageURLs: string[]) {
    this.loaderService.setLoading(this.LOADER_CONTEXT, true);
    const cachedImages$ = imageURLs.map((imageURL) =>
      this.imageHandlerService.getB64FromURL(imageURL).pipe(
        tap((data) => this.imageHandlerService.cacheImage(imageURL, data)),
        // failing to get an image should not block the entire list of images
        catchError(() => of(null))
      )
    );
    // zip emits nothing if the array is empty, but we still need to set the loader to false state
    // so just replace the zip with dummy observable that emits
    const obs = cachedImages$.length ? zip(...cachedImages$) : of([]);
    return obs.pipe(tap(() => this.loaderService.setLoading(this.LOADER_CONTEXT, false)));
  }

  /** update the displayed images by combining the list of pre-existing images
   * that are not removed, and the list of added images that are not yet saved */
  updateDisplayedImages() {
    // images that are pre-existing and have not been removed
    const savedImageURLs = this.images.filter((image) => !this.removedImages.find((other) => other === image));
    // name of files that have been added
    const addedImageNames = this.addedImages.map((imageFile) => imageFile.name);
    this.displayedImageKeys = [...savedImageURLs, ...addedImageNames];

    // reset the selected image key if it doesn't exist (or no longer exist)
    if (!this.displayedImageKeys.length) {
      this.selectedImageKey = null;
    } else if (!this.selectedImageKey || !this.displayedImageKeys.includes(this.selectedImageKey)) {
      // The condition to show selected preview image if displaySelectedImage provided, in case of removing that image
      // show the first image from displayedImageKeys[] not the displaySelectedImage
      this.selectedImageKey =
        !!this.displaySelectedImage && !this.isImageRemoved ? this.displaySelectedImage : this.displayedImageKeys[0];
    }
  }

  getCachedImage(imageURL: string) {
    return this.imageHandlerService.getCachedImage(imageURL);
  }

  /** set a new image to be previewed on the top */
  selectImage(imageKey: string) {
    this.selectedImageKey = imageKey;
  }

  /** add a new file to the image gallery and emit the changes */
  addImage(image: File) {
    // do nothing if the image handler is readonly
    if (this.readonly) {
      return;
    }

    // do nothing if the same file is added multiple times
    const imageAlreadyAdded = !!this.addedImages.find((other) => other.name === image.name);
    if (imageAlreadyAdded) {
      return;
    }
    // save the new image and emit the changes
    this.addedImages = [...this.addedImages, image];
    this.addedImagesChange.emit(this.addedImages);

    // update to reflect new added image
    this.updateDisplayedImages();

    // Get base64 data for the image file and cache it by the file name,
    // because the file is not yet uploaded so it doesn't have a url yet.
    this.imageHandlerService.getB64FromBlob(image).subscribe((data) => {
      this.imageHandlerService.cacheImage(image.name, data);
      // set this new image as the selected one so the user can have a preview
      this.selectedImageKey = image.name;
    });
  }

  /** remove an image based on it's key, either pre-existing or unsaved one */
  removeImage(imageKey: string) {
    // do nothing if the image handler is readonly
    if (this.readonly) {
      return;
    }

    // confirm the deletion of the image
    const modal = this.modalService.open({
      // 'are you sure you want to make the changes?'
      contentText: 'ÄrDuSäkerPåAttDuVillGenomföraFörändringarna_',
      buttons: {
        [ModalEventType.NEGATIVE]: { text: 'TaBort' }, // delete
        [ModalEventType.NEUTRAL]: { text: 'Avbryt' } // cancel
      }
    });
    modal.close.pipe(filter((event) => event.eventType === ModalEventType.NEGATIVE)).subscribe(() => {
      const isUnsavedImage = !!this.addedImages.find((other) => other.name === imageKey);
      if (isUnsavedImage) {
        // if the image that has been removed has not yet been saved
        // remove it from the added images and emit the changes
        this.addedImages = this.addedImages.filter((other) => other.name !== imageKey);
        this.addedImagesChange.emit(this.addedImages);
      } else {
        // otherwise add it to the removed image URLs and emit the changes
        this.removedImages = [...this.removedImages, imageKey];
        this.removedImagesChange.emit(this.removedImages);
      }

      // remove the cache for the image to be removed and reconstruct the displayed images
      this.imageHandlerService.removeCachedImage(imageKey);
      this.isImageRemoved = true;
      this.updateDisplayedImages();
    });
  }

  /** handle file input change events */
  onFileChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    if (!files.length) {
      return;
    }

    const targetFile = files.item(0);

    // validate the item size if a limit is set
    if (this.maxItemSize && targetFile.size > this.maxItemSize) {
      inputElement.value = '';
      const maxSizeMB = this.qtyService.toTargetSizeUnit(this.maxItemSize);
      // The file can be a maximum of {0} MB.
      const translation = this.translateService.instant('FilenFårMaxVara_0_MBStor_', maxSizeMB);
      this.toastrService.warning(translation);
    } else {
      this.addImage(targetFile);
    }

    // update the input value, so if the user selects the same file
    // two times in a row we still get an event
    inputElement.value = '';
  }

  ngOnInit(): void {
    this.updateDisplayedImages();
    // cache pre-existing images
    this.imageCachingSub.add(this.cacheImagesArray(this.displayedImageKeys).subscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['images']?.currentValue) {
      return;
    }
    // If image urls array changes, we first cache the new ones,
    // and only after that remove the previous ones from cache. This is because
    // the current and previous arrays may overlap, and if we first remove
    // any previous image that's also part of the incoming batch, we'll need to
    // fetch it again, with another GET request.
    const newImages = changes['images'].currentValue as string[];
    const oldImages = (this.displayedImageKeys || []) as string[];
    const cacheImagesSub = this.cacheImagesArray(newImages).subscribe(() => {
      oldImages.forEach((image) => this.imageHandlerService.removeCachedImage(image));
    });

    this.imageCachingSub.unsubscribe();
    this.imageCachingSub = new Subscription();
    this.imageCachingSub.add(cacheImagesSub);

    // also reset the added and removed images
    this.addedImages = [];
    this.removedImages = [];
    this.updateDisplayedImages();
  }

  public openImage(imageKey: string) {
    const imageSrc = this.imageHandlerService.getCachedImage(imageKey);
    const newWindow = window.open('');
    newWindow.document.title = this.imageHeader || '';
    newWindow.document.body.innerHTML = `<div style='text-align: center;'><img src=${imageSrc} style='height: 100%'></div>`;
  }

  ngOnDestroy(): void {
    this.imageCachingSub.unsubscribe();
  }
}
